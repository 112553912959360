export const getOptionsByStatus = (status) => {
  const mapConditions = new Map([
    ["CANCELED", { text: "Cancelado", color: "error", icon: "error" }],
    ["ACTIVE", { text: "Ativo", color: "primary", icon: "check_circle" }],
    [
      "SUSPENDED",
      { text: "Pagamento não efetuado", color: "warning", icon: "warning" },
    ],
    ["EXPIRED", { text: "Expirada", color: "warning", icon: "warning" }],
  ]);
  return mapConditions.get(status) || {};
};
