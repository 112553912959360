<template>
  <v-chip
    v-if="friendlyStatus.text"
    outlined
    :color="friendlyStatus.color"
    label
  >
    <span :class="textClass">{{ friendlyStatus.text }}</span>
  </v-chip>
</template>

<script>
import { getOptionsByStatus } from "@/utils/subscriptions-status";

export default {
  name: "SubscriptionStatusChip",
  props: {
    status: {
      type: String,
      required: true,
    },
    textClass: {
      type: String,
      default: "body-2",
    },
  },
  computed: {
    friendlyStatus() {
      return getOptionsByStatus(this.status);
    },
  },
};
</script>

<style scoped></style>
